import { FC } from "react";
import Select, { GroupBase, OptionsOrGroups, PropsValue } from "react-select";

import { QueryStateActions } from "src/pages/dashboard/types";
import { CaseFilterProps } from "./types";

const TableFilters: FC<CaseFilterProps> = ({ state, dropdownData, inputPlaceholder, dispatch, handleSearchWithQueryParam, onClearButtonClicked, onStatusChange }): JSX.Element => {
  const handleDateFromChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: QueryStateActions.CHANGE_FROM_DATE,
      payload: e.target.value
    });
  };

  const handleDateToChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: QueryStateActions.CHANGE_TO_DATE,
      payload: e.target.value
    });
  };

  const handleSearchQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: QueryStateActions.CHANGE_SEARCH_QUERY,
      payload: e.target.value
    });
  };

  const onEnterPress  = (e:React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSearchWithQueryParam()
    }
  }

  return (
    <div className="row g-2">
      <div className="col-12 col-sm-12 col-md-12 col-lg-6">
        <div className="row g-2">
          <div className="col-12 col-sm-12 col-md-4">
            <Select
              options={dropdownData as unknown as OptionsOrGroups<string | boolean, GroupBase<string | boolean>>}
              noOptionsMessage={() => ""}
              onChange={(val) => {
                const selectedValue = val as unknown as { label: string; value: string | boolean };
                (typeof selectedValue.value === "boolean" ) ?
                  dispatch({
                    type: QueryStateActions.CHANGE_CASE_STATUS,
                    payload: selectedValue.value,
                  }) :
                  dispatch({
                    type: QueryStateActions.CHANGE_CATEGORY_TYPE,
                    payload: selectedValue.value,
                  });
                onStatusChange(selectedValue.label);
              }}
              isSearchable={false}
              value={((openValue: string | boolean) => {
               
                const data = dropdownData.find(singleStatus => {
                  return singleStatus.value === openValue;
              });
                if (data) return data as unknown as PropsValue<string | boolean>;
              })(typeof state.open === "boolean" ? state.open : state.category_type)}
      
            />
          </div>

          <div className="col-6 col-sm-6 col-md-4">
            <input onChange={handleDateFromChange} type="date" value={state.date_from} className="form-control rounded-1" />
          </div>

          <div className="col-6 col-sm-6 col-md-4">
            <input onChange={handleDateToChange} type="date" value={state.date_to} className="form-control rounded-1" />
          </div>
        </div>
      </div>

      <div className="col-12 col-sm-12 col-md-12 col-lg-6">
        <div className="row g-2">
          <div className="col-12 col-sm-12 col-md-7 col-lg-8">
            <input type="text" onChange={handleSearchQueryChange} value={state.search_query}
                   placeholder={inputPlaceholder} className="form-control rounded-1" onKeyDown={onEnterPress}/>
          </div>

          <div className="col-12 col-sm-12 col-md-5 col-lg-4">
            <div className="row g-2">
              <div className="col-6">
                <button type="button" onClick={onClearButtonClicked} className="btn btn-light border-secondary w-100">
                  Clear
                </button>
              </div>
              <div className="col-6">
                <button type="button" onClick={() => {
                  dispatch({type: QueryStateActions.CHANGE_PAGE, payload: 1,});
                  handleSearchWithQueryParam({...state, page: 1});
                }} className="btn btn-primary w-100">
                  <i className="bi bi-search me-2"></i> Find
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableFilters;
